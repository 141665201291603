import React from "react";

const Select = ({ label, options, name, onChange, value, icon }) => {
  return (
    <div className="mb-6">
      {label && (
        <label htmlFor={name} className="block text-gray-600 mb-2">
          {label}
        </label>
      )}
      <div className="flex items-center border border-gray-300 rounded-md">
        {icon && <span className="ml-3 text-gray-400">{icon}</span>}
        <select
          name={name}
          onChange={onChange}
          value={value}
          className="w-full p-2.5 outline-none"
          required
        >
          <option value="">Sélectionner une option</option>
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
