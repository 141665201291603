import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md min-h-screen">
      <h1 className="text-4xl font-extrabold text-gray-800 mb-8">
        Politique de Confidentialité
      </h1>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">1. Introduction</h2>
      <p className="text-gray-600 mb-4">
        Chez Beer Counter, nous nous engageons à protéger votre vie privée.
        Cette politique de confidentialité explique comment nous collectons,
        utilisons, partageons et protégeons vos informations personnelles
        lorsque vous utilisez notre service.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        2. Informations que nous collectons
      </h2>
      <p className="text-gray-600 mb-4">
        Nous collectons les informations suivantes :
      </p>
      <ul className="list-disc list-inside text-gray-600 mb-4">
        <li>
          Informations d'inscription : nom d'utilisateur, adresse email, mot de
          passe
        </li>
        <li>
          Informations sur la consommation : nombre de bières enregistrées.
        </li>
        <li>
          Informations techniques : adresse IP, type de navigateur, et autres
          données de connexion.
        </li>
      </ul>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        3. Utilisation des informations
      </h2>
      <p className="text-gray-600 mb-4">
        Nous utilisons les informations collectées pour :
      </p>
      <ul className="list-disc list-inside text-gray-600 mb-4">
        <li>Fournir et améliorer notre service.</li>
        <li>Gérer votre compte utilisateur.</li>
        <li>Personnaliser votre expérience utilisateur.</li>
        <li>
          Communiquer avec vous à propos de mises à jour et de nouvelles
          fonctionnalités.
        </li>
        <li>Assurer la sécurité et prévenir les fraudes.</li>
      </ul>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        4. Partage des informations
      </h2>
      <p className="text-gray-600 mb-4">
        Nous ne partageons pas vos informations personnelles avec des tiers,
        sauf dans les cas suivants :
      </p>
      <ul className="list-disc list-inside text-gray-600 mb-4">
        <li>Avec votre consentement explicite.</li>
        <li>Pour se conformer à des obligations légales.</li>
        <li>
          Pour protéger nos droits et notre sécurité ainsi que ceux de nos
          utilisateurs.
        </li>
        <li>
          Avec des prestataires de services tiers qui nous aident à exploiter
          notre service, sous réserve qu'ils respectent la confidentialité de
          vos informations.
        </li>
      </ul>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        5. Sécurité des informations
      </h2>
      <p className="text-gray-600 mb-4">
        Nous mettons en œuvre des mesures de sécurité techniques et
        organisationnelles pour protéger vos informations contre l'accès non
        autorisé, l'altération, la divulgation ou la destruction.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">6. Vos droits</h2>
      <p className="text-gray-600 mb-4">
        Vous avez le droit d'accéder, de corriger, de supprimer et de limiter
        l'utilisation de vos informations personnelles. Pour exercer ces droits,
        veuillez nous contacter à l'adresse email ci-dessous.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        7. Conservation des données
      </h2>
      <p className="text-gray-600 mb-4">
        Nous conservons vos informations personnelles aussi longtemps que
        nécessaire pour fournir notre service et pour nous conformer à nos
        obligations légales. Vous pouvez demander la suppression de votre compte
        et de vos données à tout moment.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        8. Modifications de la politique de confidentialité
      </h2>
      <p className="text-gray-600 mb-4">
        Nous nous réservons le droit de modifier cette politique de
        confidentialité à tout moment. Les modifications seront publiées sur
        cette page et entreront en vigueur immédiatement. Nous vous encourageons
        à consulter régulièrement cette page pour rester informé des
        changements.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">9. Contact</h2>
      <p className="text-gray-600 mb-4">
        Si vous avez des questions ou des préoccupations concernant cette
        politique de confidentialité, veuillez nous contacter à l'adresse
        suivante : counter.beer.add@gmail.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
