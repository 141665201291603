import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUserById,
  updateUserById,
  updateUserPassword,
} from "../../services/users/users.services";
import {
  getAllBeersOfThePlatform,
  getNumberBeerByUserId,
} from "../../services/beer/beer.services";
import Swal from "sweetalert2";
import CardProfil from "./Info/elements/CardProfil";
import defaultProfilePic from "../../assets/beer2.webp";
import { getUserStats } from "../../services/stats/stats.services";

const HomeProfil = ({ user }) => {
  const navigate = useNavigate();
  const [allBeersInPlatform, setAllBeersInPlatform] = useState();
  const [beerCount, setBeerCount] = useState(0);
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    school: { name: "" },
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [stats, setStats] = useState({
    totalBeers: 0,
    totalConsumptionTime: 0,
    level: 0,
  });

  useEffect(() => {
    if (user) {
      const fetchStats = async () => {
        const userStats = await getUserStats(user.userId);
        setStats(userStats);
      };
      const fetchBeers = async () => {
        const allBeers = await getAllBeersOfThePlatform();
        setAllBeersInPlatform(allBeers.data.length);
      };
      fetchBeers();
      fetchStats();
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const beerCountResult = await getNumberBeerByUserId(user?.userId);
        const userDataResult = await getUserById(user?.userId);

        setUserData(userDataResult.data);
        setBeerCount(beerCountResult.data.count);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [user?.userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserById(user?.userId, userData);
      Swal.fire({
        icon: "success",
        title: "Mise à jour réussie !",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Échec de la mise à jour !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserPassword(user?.userId, passwordData);
      Swal.fire({
        icon: "success",
        title: "Mot de passe mis à jour avec succès !",
        showConfirmButton: false,
        timer: 1500,
      });
      setPasswordData({ currentPassword: "", newPassword: "" });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Échec de la mise à jour du mot de passe !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const consoTotal = allBeersInPlatform
    ? ((beerCount / allBeersInPlatform) * 100).toFixed(2)
    : 0;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-6">
      {/* Profil et Formulaires */}
      <div className="flex flex-col items-center">
        <form onSubmit={handleSubmit} className="w-full max-w-md">
          <div className="text-center mb-4">
            <img
              src={defaultProfilePic}
              alt="Profile"
              className="w-32 h-32 rounded-full mx-auto mb-4"
            />
            <h2 className="text-2xl font-bold">{userData.username}</h2>
          </div>
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-700">
              Pseudo
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={userData.username}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="school" className="block text-gray-700">
              École
            </label>
            <input
              type="text"
              id="school"
              name="school"
              value={userData.school.name}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              disabled
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-black text-white rounded-md mt-4"
          >
            Mettre à jour
          </button>
        </form>

        <form onSubmit={handlePasswordSubmit} className="w-full max-w-md mt-6">
          <div className="mb-4">
            <label htmlFor="currentPassword" className="block text-gray-700">
              Mot de passe actuel
            </label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={passwordData.currentPassword}
              onChange={handlePasswordChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="newPassword" className="block text-gray-700">
              Nouveau mot de passe
            </label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={passwordData.newPassword}
              onChange={handlePasswordChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-black text-white rounded-md mt-4"
          >
            Mettre à jour le mot de passe
          </button>
        </form>
      </div>

      {/* Section Statistiques */}
      <div className="flex flex-col items-center gap-6">
        <h1 className="text-4xl lg:text-6xl font-bold text-gray-800">
          Niveau {stats.level}
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center">
          <CardProfil
            title={"Statistiques"}
            onClick={() => navigate(`/profil/stats/${user?.userId}`)}
            subtitle={"Voir les statistiques"}
          />
          <CardProfil
            title={"Badges"}
            onClick={() => navigate(`/profil/badges/${user?.userId}`)}
            subtitle={"Voir vos badges"}
          />
          <CardProfil
            title={"Soirées"}
            disabled={true}
            onClick={() => navigate(`/profil/party/${user?.userId}`)}
            subtitle={"Voir les soirées"}
          />
        </div>

        <p className="text-center text-lg lg:text-2xl">
          <div>
            Vous avez passé{" "}
            <span className="font-bold text-yellow-400 text-xl lg:text-3xl">
              {stats.totalConsumptionTime < 1
                ? `${Math.ceil(stats.totalConsumptionTime * 60)} minute(s)`
                : `${Math.ceil(stats.totalConsumptionTime)} heure(s)`}
            </span>{" "}
            à consommer des bières.
          </div>
          heures à boire des bières, ce qui correspond à{" "}
          <span className="font-bold text-yellow-400 text-xl lg:text-3xl">
            {stats.totalBeers}
          </span>{" "}
          bières. Vous êtes également responsable de{" "}
          <span className="font-bold text-yellow-400 text-xl lg:text-3xl">
            {consoTotal}%{" "}
          </span>
          de la consommation totale de la plateforme.
        </p>
      </div>
    </div>
  );
};

export default HomeProfil;
