import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardProfil from "./elements/CardProfil";
import {
  getUserStats,
  getAllBeersAndDetails,
} from "../../../services/stats/stats.services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import MapModal from "../modal/MapModal";
import Badges from "./Badges";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

// Register the necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Stats = ({ type }) => {
  const { userId } = useParams();
  const [stats, setStats] = useState({
    totalBeers: 0,
    totalLiters: 0,
    totalSpent: 0,
    mostFrequentedBars: [],
    totalConsumptionTime: 0,
    averageAlcoholDegree: 0,
    level: 0,
    badges: [],
    allBadges: [],
    createAt: "",
  });

  const [beerData, setBeerData] = useState([]);
  const [openModalMap, setOpenModalMap] = useState(false);

  const handleOpenModalMap = () => {
    setOpenModalMap(true);
  };

  useEffect(() => {
    const fetchStats = async () => {
      const userStats = await getUserStats(userId);
      setStats(userStats);
    };

    const fetchBeerData = async () => {
      const response = await getAllBeersAndDetails(userId);
      setBeerData(response.data);
    };

    fetchStats();
    fetchBeerData();
  }, [userId]);

  // Calculer le temps total de consommation pour toutes les bières (indépendant des dates)
  const totalConsumptionTime = beerData.reduce((acc, beer) => {
    const timePerBeer = (beer.capacity / 500) * 20; // Temps en minutes pour consommer une bière
    return acc + timePerBeer; // Additionne le temps pour chaque bière
  }, 0);

  // Formatage du temps (heures et minutes)
  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return hours > 0
      ? `${hours} heure(s) ${remainingMinutes} minute(s)`
      : `${remainingMinutes} minute(s)`;
  };

  // Agrégation des données par date pour les autres statistiques
  const aggregateDataByDate = (data, valueAccessor) => {
    return data.reduce((acc, item) => {
      const date = moment(item.date).format("YYYY-MM-DD");
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date] += valueAccessor(item);
      return acc;
    }, {});
  };

  const createChartData = (aggregatedData, label, format = "MMM YYYY") => {
    const sortedDates = Object.keys(aggregatedData).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    return {
      labels: sortedDates.map((date) => moment(date).format(format)),
      datasets: [
        {
          label: label,
          data: sortedDates.map((date) => aggregatedData[date]),
          fill: false,
          borderColor: "#4c51bf",
        },
      ],
    };
  };

  const beersOverTime = aggregateDataByDate(beerData, () => 1);
  const totalLitersOverTime = aggregateDataByDate(
    beerData,
    (beer) => beer.capacity / 1000
  );
  const totalSpentOverTime = aggregateDataByDate(
    beerData,
    (beer) => beer.price || 0
  );
  const averageAlcoholDegreeOverTime = aggregateDataByDate(
    beerData,
    (beer) => beer.degres || 0
  );

  return (
    <div className="flex flex-col justify-center items-center gap-20 p-4">
      <div>
        <h1 className="text-6xl font-bold text-gray-800">
          Niveau {stats.level}
        </h1>
        <p className="text-lg text-gray-600">
          Inscrit depuis le {moment(stats.createAt).format("DD MMMM YYYY")}
        </p>{" "}
      </div>

      {type === "badges" ? (
        <Badges badges={stats.badges} allBadges={stats.allBadges} />
      ) : (
        <div className="grid grid-cols-1 justify-center items-center md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-14 max-w-6xl mt-8">
          <CardProfil
            title="Nombre de bières"
            type="beers"
            data={stats.totalBeers}
            chart={{
              type: "line",
              data: createChartData(beersOverTime, "Beers Over Time"),
            }}
          />
          <CardProfil
            title="Nombre de litres"
            type="liters"
            data={stats.totalLiters.toFixed(2)}
            chart={{
              type: "line",
              data: createChartData(totalLitersOverTime, "Liters Over Time"),
            }}
          />
          <CardProfil
            title="Total des dépenses"
            type="spent"
            data={`${stats.totalSpent.toFixed(2)} €`}
            chart={{
              type: "line",
              data: createChartData(totalSpentOverTime, "Spent Over Time"),
            }}
          />
          <CardProfil
            title="Temps total de consommation"
            type="time"
            data={formatTime(totalConsumptionTime)} // Utilisation du temps total calculé indépendamment des dates
            chart={null} // Pas besoin de graphique pour le temps total de consommation
          />
          <CardProfil
            title="Degré moyen d'alcool"
            type="degree"
            data={`${stats.averageAlcoholDegree.toFixed(2)} %`}
            chart={{
              type: "line",
              data: createChartData(
                averageAlcoholDegreeOverTime,
                "Alcohol Degree Over Time"
              ),
            }}
          />
          <CardProfil
            title="Bars les plus fréquentés"
            type="bars"
            onClick={handleOpenModalMap}
            data={
              <ul className="text-black text-lg">
                {stats.mostFrequentedBars.map((bar) => (
                  <li key={`${bar.name}-${bar.lat}-${bar.long}`}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                    {bar.name}: {bar.count} fois
                  </li>
                ))}
              </ul>
            }
          />
          <CardProfil
            title="Niveau"
            type="level"
            data={stats.level}
            chart={{
              type: "line",
              data: createChartData(beersOverTime, "Level Over Time"),
            }}
          />
          <MapModal
            isOpen={openModalMap}
            onRequestClose={() => setOpenModalMap(false)}
            bars={stats.mostFrequentedBars}
          />
        </div>
      )}
    </div>
  );
};

export default Stats;
