import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const addBeer = (beerData) => {
  const req = axios.post(`${API_URL}/beers/add-beer`, beerData);
  if (!req) return;
  return req;
};
export const removeBeer = (beerId) => {
  const req = axios.delete(`${API_URL}/beers/${beerId}`);
  if (!req) return;
  return req;
};

export const getNumberBeerByUserId = (userId) => {
  const req = axios.get(`${API_URL}/beers/get-count/${userId}`);
  if (!req) return;
  return req;
};

export const getAllBeersAndDetails = (userId) => {
  const req = axios.get(`${API_URL}/beers/get-details/${userId}`);
  if (!req) return;
  return req;
};

export const getBeerById = (beerId) => {
  const req = axios.get(`${API_URL}/beers/${beerId}`);
  if (!req) return;
  return req;
};

export const updateBeerById = (beerId, beerData) => {
  const req = axios.put(`${API_URL}/beers/${beerId}`, beerData);
  if (!req) return;
  return req;
};

export const getAllBeersOfThePlatform = () => {
  const req = axios.get(`${API_URL}/beers`);
  if (!req) return;
  return req;
};
