import React, { useState } from "react";
import { addStudentToSchool } from "../../services/schools/schools.services";

const AddStudentToSchool = () => {
  const [formData, setFormData] = useState({ userId: "", schoolCode: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addStudentToSchool(formData);
      alert("Student added to school successfully!");
    } catch (error) {
      console.error(error);
      alert("Failed to add student.");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md mx-auto p-4 shadow-md rounded-md bg-white"
    >
      <h2 className="text-2xl mb-4">Add Student to School</h2>
      <input
        type="text"
        name="userId"
        placeholder="User ID"
        onChange={handleChange}
        className="w-full p-2 mb-2 border border-gray-300 rounded-md"
        required
      />
      <input
        type="text"
        name="schoolCode"
        placeholder="School Code"
        onChange={handleChange}
        className="w-full p-2 mb-2 border border-gray-300 rounded-md"
        required
      />
      <button
        type="submit"
        className="w-full p-2 bg-blue-500 text-white rounded-md"
      >
        Add Student
      </button>
    </form>
  );
};

export default AddStudentToSchool;
