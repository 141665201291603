import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faEuroSign,
  faClock,
  faPercent,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const icons = {
  beers: faBeer,
  liters: faBeer,
  spent: faEuroSign,
  time: faClock,
  degree: faPercent,
  bars: faChartBar,
};

const CardProfil = ({
  title,
  subtitle,
  data,
  type,
  onClick,
  chart,
  disabled,
}) => {
  return (
    <div
      className={
        disabled
          ? "flex justify-center flex-col items-center bg-gradient-to-r from-gray-400 to-gray-500 shadow-xl rounded-lg p-6 cursor-not-allowed transform transition-transform hover:scale-105 w-full h-full pointer-events-none"
          : "flex justify-center flex-col items-center bg-gradient-to-r from-yellow-400 to-yellow-500 shadow-xl rounded-lg p-6 cursor-pointer transform transition-transform hover:scale-105 w-full h-full"
      }
      onClick={onClick}
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl text-center font-semibold text-white mr-2">
          {title}
        </h3>
        <FontAwesomeIcon icon={icons[type]} className="text-white text-3xl" />
      </div>
      {subtitle && <p className="text-white text-lg mb-6">{subtitle}</p>}
      <p className="text-white text-4xl font-extrabold mb-4">{data}</p>
      {chart && (
        <div className="w-full h-40">
          <Line data={chart.data} options={chart.options} />
        </div>
      )}
    </div>
  );
};

export default CardProfil;
