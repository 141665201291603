import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const Button = ({
  children,
  onClick,
  type = "primary",
  disabled,
  AddtionnalClasses,
  link, // Ajout de la prop link
  icon,
  iconColor,
  buttonType = "button", // Par défaut, type du bouton est "button"
}) => {
  const baseClasses =
    "w-full px-4 py-2 focus:outline-none rounded-md flex items-center justify-center transition duration-300";

  const typeClasses = {
    primary: "bg-black text-white hover:bg-yellow-600",
    primary_border: "border border-black text-black hover:bg-yellow-600",
    secondary: "bg-secondary-600 text-white",
    secondary_border: "border border-secondary-600 text-secondary-600",
    white: "bg-white text-black hover:bg-gray-300",
    white_border: "border border-black text-black hover:bg-gray-300",
  };

  const selectedClasses = typeClasses[type] || typeClasses.primary;

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    if (onClick) {
      onClick(e);
    }
  };

  const content = (
    <>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={icon ? "mr-2" : ""}
          color={iconColor}
        />
      )}
      {children}
    </>
  );

  if (link) {
    return (
      <Link
        to={link}
        onClick={handleClick}
        className={`${baseClasses} ${selectedClasses} ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        } ${AddtionnalClasses}`}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      type={buttonType}
      onClick={handleClick}
      className={`${baseClasses} ${selectedClasses} ${
        disabled ? "opacity-50 cursor-not-allowed" : ""
      } ${AddtionnalClasses}`}
    >
      {content}
    </button>
  );
};
