import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md min-h-screen">
      <h1 className="text-4xl font-extrabold text-gray-800 mb-8">Contact</h1>
      <p className="text-gray-600 mb-4">
        Si vous avez des questions ou des préoccupations, n'hésitez pas à nous
        contacter par email.
      </p>
      <div className="flex items-center mb-4">
        <FontAwesomeIcon
          icon={faEnvelope}
          className="text-blue-500 text-2xl mr-4"
        />
        <p className="text-gray-600">
          Email:{" "}
          <a
            href="mailto:counter.beer.add@gmail.com"
            className="text-blue-500 hover:underline"
          >
            counter.beer.add@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
