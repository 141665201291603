import React, { useState } from "react";
import axios from "axios";
import { addBeer } from "../../services/beer/beer.services";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faTrophy,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../elements/Inputs/Input";
import { Button } from "../elements/Buttons/Button";

const capacityOptions = [
  { label: "Demi", value: 250 },
  { label: "Pinte", value: 500 },
  { label: "Galopin", value: 150 },
  { label: "Girafe", value: 3000 },
];

const typeBeer = [
  { label: "Blonde", value: "blonde" },
  { label: "Brune", value: "brune" },
  { label: "Ambrée", value: "ambrée" },
  { label: "Blanche", value: "blanche" },
  { label: "Rousse", value: "rousse" },
  { label: "IPA", value: "IPA" },
  { label: "Triple", value: "triple" },
  { label: "Stout", value: "stout" },
  { label: "Pils", value: "pils" },
  { label: "Lager", value: "lager" },
  { label: "Saison", value: "saison" },
  { label: "Fruitée", value: "fruitée" },
  { label: "Blanche", value: "blanche" },
  { label: "Autre", value: "autre" },
];

const AddBeer = ({ userId }) => {
  const [formData, setFormData] = useState({
    placeWhereDrank: { name: "", country: "", city: "", lat: null, long: null },
    capacity: 500,
    price: 10,
    degres: 6,
    type: "blonde",
  });
  const [count, setCount] = useState(1);
  const [showDetails, setShowDetails] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("placeWhereDrank")) {
      const field = name.split(".")[1];
      setFormData({
        ...formData,
        placeWhereDrank: { ...formData.placeWhereDrank, [field]: value },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleCountChange = (e) => {
    setCount(Number(e.target.value));
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleLocationClick = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await axios.get(
              `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
            );

            const { address, name } = response.data;
            const country = address.country || "";
            const city = address.city || address.town || address.village || "";

            console.log("localisation", response.data);

            setFormData((prevData) => ({
              ...prevData,
              placeWhereDrank: {
                ...prevData.placeWhereDrank,
                name: name || "",
                lat: latitude,
                long: longitude,
                country,
                city,
              },
            }));
          } catch (error) {
            console.error("Error fetching location data", error);
          }
        },
        (error) => {
          console.error("Error obtaining location", error);
        }
      );
    } else {
      console.error("Geolocation not supported");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSend = {
        userId,
        capacity: formData.capacity,
        ...(formData.placeWhereDrank && {
          placeWhereDrank: formData.placeWhereDrank,
        }),
        ...(formData.price && { price: Number(formData.price) }),
        ...(formData.degres && { degres: Number(formData.degres) }),
        ...(formData.type && { type: formData.type }),
      };

      const beersData = Array(count).fill(dataToSend);

      await Promise.all(beersData.map((beerData) => addBeer(beerData)));

      Swal.fire({
        icon: "success",
        title: `${count} bière(s) ajoutée(s) avec succès !`,
        showConfirmButton: false,
        timer: 1500,
      });

      setFormData({
        placeWhereDrank: {
          name: "",
          country: "",
          city: "",
          lat: null,
          long: null,
        },
        capacity: 500,
        price: "",
        degres: "",
      });
      setCount(1);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Échec de l'ajout de la bière !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <form
        onSubmit={handleSubmit}
        className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg transform"
      >
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Ajouter des bières
        </h2>
        <div className="mb-4">
          <label htmlFor="count" className="block text-gray-600 mb-2">
            Nombre de bières
          </label>
          <input
            type="number"
            id="count"
            name="count"
            value={count}
            onChange={handleCountChange}
            min="1"
            className="w-full p-2.5 border border-gray-300 rounded-md outline-none"
            required
          />
          <p className="italic">
            Par défaut, une bière ajoutée correspond à une pinte, avec un prix
            de 10 € et un taux d'alcool de 6 %.
          </p>
        </div>
        <button
          type="button"
          onClick={toggleDetails}
          className="w-full py-2 px-4 mb-4 bg-gray-300 text-black rounded-md hover:bg-gray-400 transition duration-300 flex items-center justify-center"
        >
          {showDetails ? (
            <>
              <FontAwesomeIcon icon={faMinus} className="mr-2" />
              Réduire les infos
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Ajouter plus d'infos
            </>
          )}
        </button>

        {showDetails && (
          <>
            <div className="mb-4">
              <button
                type="button"
                onClick={toggleModal}
                className="w-full py-2 px-4 mb-4 bg-gray-300 text-black rounded-md hover:bg-gray-400 transition duration-300 flex items-center justify-center"
              >
                Ajouter un lieu de consommation
              </button>
            </div>
            <div className="mb-4">
              <label htmlFor="capacity" className="block text-gray-600 mb-2">
                Capacité
              </label>
              <select
                id="capacity"
                name="capacity"
                value={formData.capacity}
                onChange={handleInputChange}
                className="w-full p-2.5 border border-gray-300 rounded-md outline-none"
                required
              >
                {capacityOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="type" className="block text-gray-600 mb-2">
                Type
              </label>
              <select
                id="type"
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                className="w-full p-2.5 border border-gray-300 rounded-md outline-none"
              >
                {typeBeer.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="price" className="block text-gray-600 mb-2">
                Prix (€)
              </label>
              <input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                min="0"
                step="0.01"
                className="w-full p-2.5 border border-gray-300 rounded-md outline-none"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="degres" className="block text-gray-600 mb-2">
                Degrés (%)
              </label>
              <input
                type="number"
                id="degres"
                name="degres"
                value={formData.degres}
                onChange={handleInputChange}
                min="0"
                step="0.1"
                className="w-full p-2.5 border border-gray-300 rounded-md outline-none"
              />
            </div>
          </>
        )}
        <button
          type="submit"
          className="w-full py-2 px-4 bg-black text-white rounded-md hover:bg-yellow-600 transition duration-300 flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faBeer} className="mr-2" />
          Ajouter
        </button>
        <div className="mt-4 text-center text-gray-600">
          <FontAwesomeIcon icon={faTrophy} className="text-yellow-500 mr-2" />
          <span>
            N'oubliez pas de consulter le leaderboard pour voir votre position !
          </span>
        </div>
      </form>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-bold mb-4">Lieu de consommation</h2>
            <div className="mb-4">
              <Input
                id="placeWhereDrank.name"
                label="Nom du lieu"
                name="placeWhereDrank.name"
                value={formData.placeWhereDrank.name}
                onChange={handleInputChange}
                type="text"
                required
              ></Input>
              {formData.placeWhereDrank.name && (
                <p className="text-red-600 italic">
                  Attention le nom est ajouter automatiquement à la localisation
                  verifier que le nom est correct.
                </p>
              )}
            </div>
            <div className="mb-4">
              <Input
                id="placeWhereDrank.country"
                label="Pays"
                name="placeWhereDrank.country"
                value={formData.placeWhereDrank.country}
                onChange={handleInputChange}
                type="text"
              ></Input>
            </div>
            <div className="mb-4">
              <Input
                id="placeWhereDrank.city"
                label="Ville"
                name="placeWhereDrank.city"
                value={formData.placeWhereDrank.city}
                onChange={handleInputChange}
                type="text"
              ></Input>
            </div>
            <div className="mb-4">
              <Button type={"button"} onClick={handleLocationClick}>
                Obtenir ma localisation
              </Button>
            </div>
            <div className="flex justify-end">
              {formData.placeWhereDrank.name &&
                formData.placeWhereDrank.city &&
                formData.placeWhereDrank.country && (
                  <Button type={"button"} onClick={toggleModal}>
                    Valider
                  </Button>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddBeer;
