import React from "react";

const CGU = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md min-h-screen">
      <h1 className="text-4xl font-extrabold text-gray-800 mb-8">
        Conditions Générales d'Utilisation
      </h1>
      <p className="text-gray-600 mb-4">
        Les présentes Conditions Générales d'Utilisation (CGU) régissent
        l'utilisation du service Beer Counter. En accédant ou en utilisant notre
        service, vous acceptez d'être lié par ces CGU.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">1. Introduction</h2>
      <p className="text-gray-600 mb-4">
        Beer Counter est une application qui permet aux utilisateurs de suivre
        leur consommation de bière et de rivaliser avec leurs amis. Ces CGU
        expliquent les règles et les conditions d'utilisation de notre service.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        2. Acceptation des CGU
      </h2>
      <p className="text-gray-600 mb-4">
        En utilisant Beer Counter, vous acceptez ces CGU dans leur intégralité.
        Si vous n'acceptez pas ces CGU, vous ne devez pas utiliser notre
        service.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        3. Modification des CGU
      </h2>
      <p className="text-gray-600 mb-4">
        Nous nous réservons le droit de modifier les CGU à tout moment. Les
        modifications entreront en vigueur dès leur publication sur notre site.
        Nous vous encourageons à consulter régulièrement les CGU pour rester
        informé des changements.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        4. Services proposés
      </h2>
      <p className="text-gray-600 mb-4">
        Beer Counter propose des services de suivi de consommation de bière, de
        gestion de profil utilisateur, et de classement entre amis.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        5. Inscription et accès au compte
      </h2>
      <p className="text-gray-600 mb-4">
        Pour utiliser Beer Counter, vous devez créer un compte en fournissant
        des informations exactes et complètes. Vous êtes responsable de la
        confidentialité de votre compte et de votre mot de passe.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        6. Utilisation des services
      </h2>
      <p className="text-gray-600 mb-4">
        Vous acceptez d'utiliser Beer Counter uniquement à des fins légales et
        conformément aux présentes CGU. Toute utilisation abusive ou frauduleuse
        de notre service est strictement interdite.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        7. Responsabilités de l'utilisateur
      </h2>
      <p className="text-gray-600 mb-4">
        Vous êtes responsable de toutes les activités réalisées à partir de
        votre compte. Vous acceptez de ne pas publier de contenu inapproprié ou
        illégal sur notre plateforme.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        8. Propriété intellectuelle
      </h2>
      <p className="text-gray-600 mb-4">
        Le contenu et les services proposés par Beer Counter sont protégés par
        des droits de propriété intellectuelle. Vous ne pouvez pas utiliser ce
        contenu sans notre autorisation préalable.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        9. Protection des données personnelles
      </h2>
      <p className="text-gray-600 mb-4">
        Nous collectons et traitons vos données personnelles conformément à
        notre politique de confidentialité. Nous nous engageons à protéger vos
        données et à respecter votre vie privée.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        10. Limitation de responsabilité
      </h2>
      <p className="text-gray-600 mb-4">
        Beer Counter ne saurait être tenu responsable des dommages directs ou
        indirects résultant de l'utilisation de notre service. Nous ne
        garantissons pas l'absence d'erreurs ou d'interruptions de service.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">11. Résiliation</h2>
      <p className="text-gray-600 mb-4">
        Nous nous réservons le droit de résilier votre compte en cas de
        violation des présentes CGU. Vous pouvez également résilier votre compte
        à tout moment en nous contactant.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">
        12. Loi applicable et juridiction compétente
      </h2>
      <p className="text-gray-600 mb-4">
        Les présentes CGU sont régies par la loi française. En cas de litige,
        les tribunaux français seront seuls compétents.
      </p>

      <h2 className="text-2xl font-bold text-gray-700 mb-4">13. Contact</h2>
      <p className="text-gray-600 mb-4">
        Pour toute question concernant ces CGU, vous pouvez nous contacter à
        l'adresse suivante : counter.beer.add@gmail.com
      </p>
    </div>
  );
};

export default CGU;
