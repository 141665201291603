import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getAllUsers = () => {
  const req = axios.get(`${API_URL}/users`);
  if (!req) return;
  return req;
};

export const getUserById = (userId) => {
  const req = axios.get(`${API_URL}/users/${userId}`);
  if (!req) return;
  return req;
};

export const updateUserById = (userId, userData) => {
  const req = axios.put(`${API_URL}/users/${userId}`, userData);
  if (!req) return;
  return req;
};

export const updateUserPassword = (userId, passwordData) => {
  const req = axios.put(`${API_URL}/users/${userId}/password`, passwordData);
  if (!req) return;
  return req;
};
