import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSchoolByRegion } from "../../services/schools/schools.services";

const SchoolByRegion = () => {
  const { region } = useParams();
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await getSchoolByRegion(region);
        setSchools(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSchools();
  }, [region]);

  return (
    <div className="max-w-2xl mx-auto p-4 shadow-md rounded-md bg-white">
      <h2 className="text-2xl mb-4">Schools in {region}</h2>
      <ul>
        {schools.map((school) => (
          <li key={school._id} className="mb-2">
            {school.name} - Code: {school.code}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SchoolByRegion;
