import React, { useState } from "react";
import Swal from "sweetalert2";
import { login } from "../../services/auth/auth.services";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../elements/Inputs/Input";
import { Button } from "../elements/Buttons/Button";

const Login = ({ setToken }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    schoolCode: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await login(formData);
      setToken(response.data.token);
      Swal.fire({
        icon: "success",
        title: "Connexion réussie !",
        showConfirmButton: false,
        timer: 1500,
      });
      setIsLoading(false);
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Échec de la connexion !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg"
      >
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Connexion
        </h2>
        <div className="mb-4">
          <Input
            label="Email"
            onChange={handleChange}
            name="email"
            type="email"
            placeholder="Email"
            required
          ></Input>
        </div>
        <div className="mb-6">
          <Input
            label="Mot de passe"
            isShowPassword
            onChange={handleChange}
            name="password"
            placeholder="Mot de passe"
            required
          ></Input>
        </div>
        <Button onClick={handleSubmit} type="submit">
          {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Connexion"}
        </Button>
        <p className="mt-4 text-center text-gray-600">
          Pas de compte ?{" "}
          <a href="/register" className="text-yellow-500  hover:underline">
            Inscrivez-vous ici
          </a>
        </p>
        <p className="mt-4 text-center text-gray-600">
          mot de passe oublié ?{" "}
          <a
            href="/forgot-password"
            className="text-yellow-500  hover:underline"
          >
            Réinitialiser votre mot de passe
          </a>
        </p>
      </form>
    </div>
  );
};

export default Login;
