import React, { useEffect, useState } from "react";
import { getAllSchools } from "../../services/schools/schools.services";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSchool, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const SchoolList = () => {
  const [schools, setSchools] = useState([]);
  const navigate = useNavigate();

  const gotodetails = (code) => {
    navigate(`/school/${code}`);
  };

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await getAllSchools();
        setSchools(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSchools();
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen ">
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        <div className="text-center mb-8">
          <h2 className="text-4xl font-extrabold text-gray-800">
            Liste des écoles
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Explorez les écoles et découvrez où vos amis étudient !
          </p>
        </div>
        <ul className="space-y-4">
          {schools.map((school) => (
            <li
              key={school._id}
              className="flex items-center p-4 bg-white rounded-md shadow-md border-l-4 border-yellow-500"
            >
              <div className="flex-shrink-0 mr-4">
                <FontAwesomeIcon
                  icon={faSchool}
                  className="text-3xl text-yellow-500"
                />
              </div>
              <div className="flex-1">
                <h3 className="text-xl font-bold text-gray-700">
                  {school.name}
                </h3>
                <p className="text-gray-500">{school.region}</p>
              </div>
              <div className="flex-shrink-0">
                <button
                  onClick={() => gotodetails(school.code)}
                  className="p-2 bg-black text-white rounded-md "
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                  Détail
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SchoolList;
