import React, { useState } from "react";
import { createSchool } from "../../services/schools/schools.services";
import Randomstring from "randomstring";

const CreateSchool = () => {
  const [formData, setFormData] = useState({ name: "", code: "", region: "" });
  const [code, setCode] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createSchool(formData);
      alert("School created successfully!");
    } catch (error) {
      console.error(error);
      alert("Failed to create school.");
    }
  };

  const createCode = () => {
    const code = Randomstring.generate({
      length: 6,
      charset: "alphanumeric",
    });
    setCode(code);
    setFormData({ ...formData, code });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md mx-auto p-4 shadow-md rounded-md bg-white"
    >
      <h2 className="text-2xl mb-4">Create School</h2>
      <input
        type="text"
        name="name"
        placeholder="School Name"
        onChange={handleChange}
        className="w-full p-2 mb-2 border border-gray-300 rounded-md"
        required
      />
      <div className="flex flex-row">
        <input
          type="text"
          name="code"
          placeholder="School Code"
          onChange={handleChange}
          value={code}
          className="w-full p-2 mb-2 border border-gray-300 rounded-md"
          required
        />
        <button
          type="button"
          onClick={createCode}
          className="w-full p-2 bg-blue-500 text-white rounded-md"
        >
          Create random code
        </button>
      </div>
      <input
        type="text"
        name="region"
        placeholder="Region"
        onChange={handleChange}
        className="w-full p-2 mb-2 border border-gray-300 rounded-md"
        required
      />
      <button
        type="submit"
        className="w-full p-2 bg-blue-500 text-white rounded-md"
      >
        Create School
      </button>
    </form>
  );
};

export default CreateSchool;
