import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const createSchool = (school) => {
  const req = axios.post(`${API_URL}/schools/create`, school);
  if (!req) return;
  return req;
};

export const addStudentToSchool = (data) => {
  const req = axios.post(`${API_URL}/schools/add-student`, data);
  if (!req) return;
  return req;
};

export const getAllSchools = () => {
  const req = axios.get(`${API_URL}/schools`);

  if (!req) return;
  return req;
};

export const getSchoolByRegion = (region) => {
  const req = axios.get(`${API_URL}/schools/region/${region}`);
  if (!req) return;
  return req;
};

export const getSchoolByCode = (code) => {
  const req = axios.get(`${API_URL}/schools/${code}`);
  if (!req) return;
  return req;
};

export const getStudentsBySchool = (schoolCode) => {
  const req = axios.get(`${API_URL}/schools/${schoolCode}/students`);
  if (!req) return;
  return req;
};
