import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getAllBeersAndDetails = (userId) => {
  return axios.get(`${API_URL}/beers/get-details/${userId}`);
};

const allBadges = [
  { label: "First Sip", level: 1 },
  { label: "Five Pack", level: 1 },
  { label: "Rookie Drinker", level: 1 },
  { label: "Ten Cheers", level: 2 },
  { label: "Social Drinker", level: 2 },
  { label: "Intermediate Drinker", level: 2 },
  { label: "Quarter Century", level: 3 },
  { label: "Pub Hopper", level: 3 },
  { label: "Big Spender", level: 3 },
  { label: "Advanced Drinker", level: 3 },
  { label: "Half Century", level: 4 },
  { label: "Connoisseur", level: 4 },
  { label: "Regular", level: 4 },
  { label: "Expert Drinker", level: 4 },
  { label: "Century Club", level: 5 },
  { label: "Bar Explorer", level: 5 },
  { label: "Master of Taste", level: 5 },
  { label: "Master Drinker", level: 5 },
  { label: "Party Animal", level: 6 },
  { label: "Beer Marathon", level: 6 },
  // { label: "Golden Throat", level: 6 },
  // { label: "Beer Scholar", level: 6 },
  // { label: "Perfect Score", level: 6 },
];

const getBadges = (
  totalBeers,
  bars,
  totalSpent,
  totalLiters,
  averageAlcoholDegree,
  totalConsumptionTime
) => {
  const badges = [];

  if (totalBeers >= 1) badges.push("First Sip");
  if (totalBeers >= 5) badges.push("Five Pack");
  if (totalBeers >= 10) badges.push("Rookie Drinker");
  if (totalBeers >= 25) badges.push("Ten Cheers");
  if (totalBeers >= 50) badges.push("Half Century");
  if (totalBeers >= 100) badges.push("Century Club");

  if (Object.keys(bars).length >= 3) badges.push("Social Drinker");
  if (Object.keys(bars).length >= 5) badges.push("Intermediate Drinker");
  if (Object.keys(bars).length >= 10) badges.push("Bar Explorer");
  if (Object.keys(bars).length >= 20) badges.push("Master of Taste");
  if (Object.keys(bars).length >= 30) badges.push("Master Drinker");

  if (totalSpent >= 100) badges.push("Quarter Century");
  if (totalSpent >= 500) badges.push("Pub Hopper");
  if (totalSpent >= 1000) badges.push("Big Spender");
  if (totalSpent >= 5000) badges.push("Advanced Drinker");

  if (totalLiters >= 5) badges.push("Half Century");
  if (totalLiters >= 10) badges.push("Connoisseur");
  if (totalLiters >= 20) badges.push("Regular");
  if (totalLiters >= 50) badges.push("Expert Drinker");
  if (totalLiters >= 100) badges.push("Century Club");

  if (totalConsumptionTime >= 10 * 60) badges.push("Party Animal");
  if (totalConsumptionTime >= 24 * 60) badges.push("Beer Marathon");

  return badges;
};

export const getUserStats = async (userId) => {
  const beers = await getAllBeersAndDetails(userId);
  const beerData = beers.data;

  console.log("beerdata", beerData);
  const totalBeers = beerData.length;
  const totalLiters =
    beerData.reduce((acc, beer) => acc + beer.capacity, 0) / 1000;

  const totalSpent = beerData.reduce((acc, beer) => acc + (beer.price || 0), 0);

  const bars = {};
  beerData.forEach((beer) => {
    const { name, country, city, lat, long } = beer.placeWhereDrank || {};
    if (name && lat && long) {
      const key = `${name}-${lat}-${long}`;
      if (!bars[key]) {
        bars[key] = { count: 0, name, country, city, lat, long };
      }
      bars[key].count += 1;
    }
  });
  const mostFrequentedBars = Object.values(bars).sort(
    (a, b) => b.count - a.count
  );

  const totalConsumptionTime = beerData.reduce((acc, beer) => {
    const timePerBeer = (beer.capacity / 500) * 20; // Temps en minutes pour consommer la bière
    const timeInHours = timePerBeer / 60; // Conversion en heures
    return acc + timeInHours;
  }, 0);

  console.log("Total consumption time in hours:", totalConsumptionTime);

  const averageAlcoholDegree =
    beerData.reduce((acc, beer) => acc + (beer.degres || 0), 0) / totalBeers;

  const level = Math.floor(totalBeers / 5);
  const badges = getBadges(
    totalBeers,
    bars,
    totalSpent,
    totalLiters,
    averageAlcoholDegree,
    totalConsumptionTime
  );

  return {
    totalBeers,
    totalLiters,
    totalSpent,
    mostFrequentedBars,
    totalConsumptionTime,
    averageAlcoholDegree,
    level,
    badges,
    allBadges,
  };
};
