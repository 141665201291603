import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBeer, faChartBar, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

const Home = ({ token }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Vérifiez si le token est présent pour déterminer l'état de connexion
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [token]);

  return (
    <div className="max-w-6xl mx-auto p-6 min-h-screen flex flex-col justify-center items-center">
      <div className="text-center mb-12">
        <h1 className="text-5xl font-extrabold text-gray-800">
          Bienvenue sur Beer Counter
        </h1>
        <p className="mt-4 text-xl text-gray-600">
          Suivez votre consommation de bière et rivalisez avec vos amis !
        </p>
      </div>

      {/* <div className="flex justify-center mb-12">
        <img
          src={beerImage}
          alt="Bière"
          className="rounded-md shadow-lg w-full md:w-2/3"
        />
      </div> */}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white p-8 rounded-md shadow-md text-center">
          <FontAwesomeIcon
            icon={faBeer}
            size="3x"
            className="text-yellow-500 mb-4"
          />
          <h2 className="text-3xl font-bold text-gray-700 mb-4">
            Qu'est-ce que Beer Counter ?
          </h2>
          <p className="text-gray-600">
            Beer Counter est un moyen amusant et interactif de suivre les bières
            que vous buvez. Que vous soyez à une fête, dans un pub, ou
            simplement en train de savourer une bière chez vous, vous pouvez
            enregistrer vos consommations et voir comment vous vous situez par
            rapport aux autres.
          </p>
        </div>

        <div className="bg-white p-8 rounded-md shadow-md text-center">
          <FontAwesomeIcon
            icon={faChartBar}
            size="3x"
            className="text-yellow-500 mb-4"
          />
          <h2 className="text-3xl font-bold text-gray-700 mb-4">
            Fonctionnalités
          </h2>
          <ul className="text-gray-600">
            <li className="mb-2">
              <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />{" "}
              Enregistrez chaque bière que vous buvez
            </li>
            <li className="mb-2">
              <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />{" "}
              Consultez un classement des meilleurs buveurs
            </li>

            <li className="mb-2">
              <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />{" "}
              Visualisez des statistiques détaillées de votre consommation de
              bière
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="mt-8 p-4 bg-gray-100 rounded-lg ">
          <p className="text-gray-600 text-sm">
            L'abus d'alcool est dangereux pour la santé.{" "}
            <span className="font-bold">Alcool Info Service :</span>{" "}
            <a
              href="https://www.alcool-info-service.fr/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              0 980 980 930
            </a>{" "}
            - Pour plus d'informations, visitez{" "}
            <a
              href="https://www.alcool-info-service.fr/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              Alcool Info Service
            </a>
            .
          </p>
        </div>
      </div>
      {!isAuthenticated && (
        <div className="text-center mt-12">
          <div className="w-full flex flex-row gap-5 justify-center items-center">
            <Link to="/login">
              <button className="bg-black text-white py-2 px-4 rounded-md shadow-lg transition duration-300 transform hover:scale-105">
                Connexion
              </button>
            </Link>
            <Link to="/register">
              <button className="bg-yellow-500 text-white py-2 px-4 rounded-md shadow-lg hover:bg-yellow-500 transition duration-300 transform hover:scale-105">
                Inscription
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
