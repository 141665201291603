import React from "react";
import { removeBeer } from "../../services/beer/beer.services";
import Swal from "sweetalert2";
import { motion } from "framer-motion";
import { Button } from "../elements/Buttons/Button";

const RemoveBeerModal = ({ beerId, onClose }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await removeBeer(beerId);

      Swal.fire({
        icon: "success",
        title: "Dernière bière supprimée avec succès !",
        showConfirmButton: false,
        timer: 1500,
      });

      onClose();
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Échec de la suppression de la bière !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="bg-white p-8 rounded-lg shadow-lg z-50 max-w-md w-full"
      >
        <form onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
            Retirer la dernière bière
          </h2>
          <p className="text-center mb-4">
            Cela supprimera la dernière bière que vous avez ajoutée.
          </p>
          <Button type="submit" onClick={handleSubmit}>
            Supprimer
          </Button>
          <Button type="button" onClick={onClose}>
            Fermer
          </Button>
        </form>
      </motion.div>
    </div>
  );
};

export default RemoveBeerModal;
