import {
  faSignOutAlt,
  faUser,
  faChartBar,
  faBandage,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Avatar = ({ user, size = "md", handleLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const initials = user.username.slice(0, 2).toUpperCase();
  const navigate = useNavigate();

  const sizes = {
    sm: "w-8 h-8 text-sm",
    md: "w-12 h-12 text-md",
    lg: "w-16 h-16 text-lg",
    xl: "w-24 h-24 text-xl",
  };

  const openMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const openProfil = () => {
    setIsMenuOpen(false);
    navigate("/profil");
  };

  const openStats = () => {
    setIsMenuOpen(false);
    navigate(`/profil/stats/${user.userId}`);
  };

  const openBadges = () => {
    setIsMenuOpen(false);
    navigate(`/profil/badges/${user.userId}`);
  };

  return (
    <div className="relative">
      <div
        onClick={openMenu}
        className={`flex items-center justify-center rounded-full bg-yellow-500 text-black font-bold cursor-pointer ${sizes[size]}`}
      >
        {initials}
      </div>
      {isMenuOpen && (
        <div className="absolute right-0 mt-2  bg-white shadow-lg rounded-md z-10">
          <ul className="py-1">
            <li
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-black flex items-center"
              onClick={openProfil}
            >
              <FontAwesomeIcon icon={faUser} className="mr-2" />
              Profil
            </li>
            <li
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-black flex items-center"
              onClick={openStats}
            >
              <FontAwesomeIcon icon={faChartBar} className="mr-2" />
              Statistiques
            </li>
            <li
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-black flex items-center"
              onClick={openBadges}
            >
              <FontAwesomeIcon icon={faIdBadge} className="mr-2" />
              Mes badges
            </li>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-black flex items-center">
              <button onClick={handleLogout} className="flex items-center">
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                Déconnexion
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Avatar;
