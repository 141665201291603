// src/components/Navigation.js
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faTrophy,
  faSchool,
  faPlus,
  faUserPlus,
  faSignOutAlt,
  faSignInAlt,
  faUserCircle,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import Avatar from "./components/profil/Info/Avatar";

const Navigation = ({
  token,
  user,
  isAdmin,
  handleLogout,
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const links = (
    <>
      <Link to="/" className="flex items-center mr-4 mb-2 md:mb-0">
        <FontAwesomeIcon icon={faHome} className="mr-2" /> Accueil
      </Link>
      <Link to="/beer-list" className="flex items-center mr-4 mb-2 md:mb-0">
        <FontAwesomeIcon icon={faTrophy} className="mr-2" /> LeaderBoard
      </Link>
      <Link to="/school-list" className="flex items-center mr-4 mb-2 md:mb-0">
        <FontAwesomeIcon icon={faSchool} className="mr-2" /> Liste des écoles
      </Link>
      {token && (
        <>
          <Link to="/add-beer" className="flex items-center mr-4 mb-2 md:mb-0">
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Ajouter une bière
          </Link>
          <Link to="/who-pays" className="flex items-center mr-4 mb-2 md:mb-0">
            <FontAwesomeIcon icon={faMoneyBill} className="mr-2" /> Qui paie ?
          </Link>
          {isAdmin && (
            <>
              <Link
                to="/create-school"
                className="flex items-center mr-4 mb-2 md:mb-0"
              >
                <FontAwesomeIcon icon={faSchool} className="mr-2" /> Créer une
                école
              </Link>
              <Link
                to="/add-student"
                className="flex items-center mr-4 mb-2 md:mb-0"
              >
                <FontAwesomeIcon icon={faUserPlus} className="mr-2" /> Ajouter
                un étudiant
              </Link>
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <nav className="p-4 bg-black text-white flex justify-between items-center">
        <div className="flex items-center">
          {/* Toggle Menu Button for Mobile */}
          <button
            className="md:hidden p-2 focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>

          {/* Desktop Links */}
          <div className="hidden md:flex items-center">{links}</div>
        </div>

        {/* User avatar or auth links */}
        {token ? (
          <div className="flex items-center">
            <Avatar user={user} handleLogout={handleLogout} />
          </div>
        ) : (
          <div className="flex">
            <Link
              to="/login"
              className="bg-primary p-2 rounded mr-2 flex items-center"
            >
              <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Connexion
            </Link>
            <Link
              to="/register"
              className="bg-secondary p-2 rounded flex items-center"
            >
              <FontAwesomeIcon icon={faUserCircle} className="mr-2" />{" "}
              Inscription
            </Link>
          </div>
        )}
      </nav>

      {/* Mobile Links */}
      {isMenuOpen && (
        <div className="md:hidden bg-black text-white p-4">{links}</div>
      )}
    </>
  );
};

export default Navigation;
