import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import PinBeer from "../../../assets/pin.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faCity,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";

const beerIconUrl = PinBeer;
const icon = new L.Icon({
  iconUrl: beerIconUrl,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
  popupAnchor: [0, -30],
});

const MapModal = ({ isOpen, onRequestClose, bars }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBars, setFilteredBars] = useState(bars);
  const mapRef = useRef(null);

  useEffect(() => {
    setFilteredBars(
      bars.filter(
        (bar) =>
          bar.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          bar.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
          bar.country.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, bars]);

  const handleBarClick = (lat, long) => {
    if (mapRef.current) {
      mapRef.current.setView([lat, long], 15);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 p-6 relative flex shadow-lg rounded-lg">
        <div className="w-1/3 p-4 flex flex-col border-r border-gray-200">
          <input
            type="text"
            placeholder="Search for a bar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full mb-4 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
          <ul className="overflow-y-auto h-full space-y-2">
            {filteredBars.map((bar, index) => (
              <li
                key={index}
                className="p-2 cursor-pointer bg-white hover:bg-gray-100 rounded-md shadow-sm"
                onClick={() => handleBarClick(bar.lat, bar.long)}
              >
                <p className="font-semibold">{bar.name}</p>
                <p className="text-sm text-gray-600">
                  {bar.city}, {bar.country}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-2/3 h-[500px] relative">
          <MapContainer
            center={[48.8566, 2.3522]} // Default to Paris center
            zoom={13}
            style={{ height: "100%", width: "100%", zIndex: 0 }}
            ref={mapRef}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {Array.isArray(bars) &&
              bars.map((bar, index) => (
                <Marker key={index} position={[bar.lat, bar.long]} icon={icon}>
                  <Popup className="custom-popup">
                    <div className="flex flex-col space-y-2">
                      <p className="font-semibold">{bar.name}</p>
                      <p className="text-sm text-gray-600">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="mr-2"
                        />
                        {bar.city}, {bar.country}
                      </p>
                      <p className="text-sm text-gray-600">
                        <FontAwesomeIcon icon={faCity} className="mr-2" />
                        {bar.city}
                      </p>
                      <p className="text-sm text-gray-600">
                        <FontAwesomeIcon icon={faFlag} className="mr-2" />
                        {bar.country}
                      </p>
                    </div>
                  </Popup>
                </Marker>
              ))}
          </MapContainer>
          <button
            onClick={onRequestClose}
            className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded shadow z-50"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default MapModal;
