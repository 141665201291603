import React, { useState, useEffect } from "react";
import { register } from "../../services/auth/auth.services";
import { getAllSchools } from "../../services/schools/schools.services";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faLock,
  faSchool,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../elements/Inputs/Input";
import Select from "../elements/Select/Select";
import { Button } from "../elements/Buttons/Button";

const Register = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    school: "",
  });
  const [schools, setSchools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await getAllSchools();
        setSchools(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSchools();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (formData.password !== formData.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Les mots de passe ne correspondent pas !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setIsLoading(true);
    try {
      await register(formData);

      Swal.fire({
        icon: "success",
        title: "Inscription réussie !",
        showConfirmButton: false,
        timer: 1500,
      });

      setTimeout(() => {
        navigate("/login");
      }, 1500);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Échec de l'inscription !",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg"
      >
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Inscription
        </h2>
        <div className="mb-4">
          <Input
            label="Nom d'utilisateur"
            onChange={handleChange}
            name="username"
            type="text"
            placeholder="Nom d'utilisateur"
            required
          />
        </div>
        <div className="mb-4">
          <Input
            label="Email"
            onChange={handleChange}
            name="email"
            type="email"
            placeholder="Email"
            required
          />
        </div>
        <div className="mb-4">
          <Input
            isShowPassword
            label="Mot de passe"
            onChange={handleChange}
            name="password"
            type="password"
            placeholder="Mot de passe"
            required
          />
        </div>

        <div className="mb-4">
          <Input
            isShowPassword
            label="Confirmer le mot de passe"
            onChange={handleChange}
            name="confirmPassword"
            type="password"
            placeholder="Confirmer le mot de passe"
            required
          />
        </div>

        <div className="mb-6">
          <Select
            label="École"
            name="school"
            onChange={handleChange}
            value={formData.school}
            icon={<FontAwesomeIcon icon={faSchool} />}
            options={schools.map((school) => ({
              value: school._id,
              label: school.name,
            }))}
          />
          <div className="flex flex-col justify-center items-center">
            <p>
              Votre école n'est pas répertoriée ?{" "}
              <Link
                to="#"
                className="text-yellow-500 file:hover:underline ml-1"
                onClick={(e) => {
                  window.location.href = "mailto:counter.beer.add@gmail.com";
                  e.preventDefault();
                }}
              >
                Contactez-nous
              </Link>
            </p>
          </div>
        </div>

        <Button type="submit" onClick={handleSubmit}>
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "S'inscription"
          )}
        </Button>

        <p className="mt-4 text-center text-gray-600">
          Vous avez déjà un compte ?{" "}
          <Link to="/login" className="text-yellow-500 file:hover:underline">
            Connectez-vous ici
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Register;
