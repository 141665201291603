import React, { useState } from "react";
import { Button } from "../elements/Buttons/Button";

const WhoPays = () => {
  const [names, setNames] = useState([]);
  const [nameInput, setNameInput] = useState("");
  const [selectedName, setSelectedName] = useState(null);
  const [isRolling, setIsRolling] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const forbiddenNames = [
    "guéwen",
    "guewen",
    "gwen",
    "guewenn",
    "guegue",
    "antoine",
    "antoine1",
    "antoine2",
    "Antoine",
    "Antoine1",
    "Antoine2",
    "toinou",
  ];

  const handleAddName = (e) => {
    e.preventDefault();
    if (nameInput.trim() !== "") {
      setNames([...names, nameInput]);
      setNameInput("");
    }
  };

  const handleRandomPick = () => {
    if (names.length > 0) {
      setIsRolling(true);

      let rollingInterval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * names.length);
        setSelectedName(names[randomIndex]);
      }, 100); // Change le nom toutes les 100ms pour l'effet de défilement

      setTimeout(() => {
        clearInterval(rollingInterval);

        // Filtrer les noms pour exclure les noms interdits dans le résultat final
        const validNames = names.filter(
          (name) => !forbiddenNames.includes(name.toLowerCase())
        );

        // Si la liste filtrée est vide (tous les noms sont interdits), afficher un message d'erreur
        if (validNames.length === 0) {
          setSelectedName("Aucun nom valide !");
        } else {
          const finalRandomIndex = Math.floor(
            Math.random() * validNames.length
          );
          const finalName = validNames[finalRandomIndex];
          setSelectedName(finalName);
        }

        setIsRolling(false);
        setIsModalOpen(true); // Ouvre la modal après le tirage
      }, 3000); // L'animation dure 3 secondes
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="p-4 max-w-lg mx-auto h-screen">
      <h1 className="text-4xl font-bold text-center mb-4">
        Qui paie la tournée !?
      </h1>

      <form onSubmit={handleAddName} className="flex mb-4">
        <input
          type="text"
          placeholder="Ajouter un nom"
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
          className="flex-grow p-2 border border-gray-300 rounded-l-md outline-none"
        />
        <button
          type="submit"
          className="bg-black text-white px-4 py-2 rounded-r-md hover:bg-yellow-600 transition duration-300"
        >
          Ajouter
        </button>
      </form>

      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Liste des noms :</h2>
        <ul className="list-disc pl-5">
          {names.length > 0 ? (
            names.map((name, index) => (
              <li key={index} className="text-lg text-gray-700">
                {name}
              </li>
            ))
          ) : (
            <li className="text-gray-500">Aucun nom ajouté</li>
          )}
        </ul>
      </div>

      <Button
        onClick={handleRandomPick}
        disabled={isRolling || names.length === 0}
      >
        {isRolling ? "Tirage en cours..." : "Tirer un nom au hasard"}
      </Button>

      <div className="flex justify-center mt-4">
        <div
          className={`w-64 h-16 flex items-center justify-center text-2xl font-bold text-yellow-500 transition-transform duration-300 ease-in-out ${
            isRolling ? "" : ""
          }`}
        >
          {selectedName || ""}
        </div>
      </div>

      {/* Modal personnalisée */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm mx-auto flex flex-col items-center">
            <h2 className="text-3xl font-bold mb-4">🎉 Résultat 🎉</h2>
            <p className="text-3xl font-bold text-yellow-500 mb-4">
              {selectedName}
            </p>
            <Button onClick={closeModal}>Impeccable</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhoPays;
