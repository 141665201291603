import React, { useEffect, useState } from "react";
import { getAllUsers } from "../../services/users/users.services";
import { getAllSchools } from "../../services/schools/schools.services";
import {
  getNumberBeerByUserId,
  getAllBeersAndDetails,
} from "../../services/beer/beer.services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faMedal,
  faPlus,
  faMinus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import RemoveBeerModal from "./RemoveBeerModal";
import { Button } from "../elements/Buttons/Button";

const BeerList = ({ userId, user }) => {
  const [users, setUsers] = useState([]);
  const [userBeers, setUserBeers] = useState({});
  const [userBeerDetails, setUserBeerDetails] = useState({});
  const [schools, setSchools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [lastBeerId, setLastBeerId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const usersResponse = await getAllUsers();
      const schoolsResponse = await getAllSchools();

      setSchools(schoolsResponse.data);

      const beersCount = {};
      const beersDetails = {};

      for (const user of usersResponse.data) {
        const beerCountResponse = await getNumberBeerByUserId(user._id);
        beersCount[user._id] = beerCountResponse.data.count;

        const beerDetailsResponse = await getAllBeersAndDetails(user._id);
        beersDetails[user._id] = beerDetailsResponse.data;
      }

      // Trier les utilisateurs par nombre de bières
      const sortedUsers = usersResponse.data.sort((a, b) => {
        return (beersCount[b._id] || 0) - (beersCount[a._id] || 0);
      });

      setUsers(sortedUsers);
      setUserBeers(beersCount);
      setUserBeerDetails(beersDetails);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const getSchoolName = (userId) => {
    const school = schools.find((school) => school.students.includes(userId));
    return school ? school.name : "No School";
  };

  const handleRemoveBeer = () => {
    const lastBeer = userBeerDetails[user.userId].slice(-1)[0];
    setLastBeerId(lastBeer._id);
    setShowRemoveModal(true);
  };

  return (
    <div className="max-w-4xl mx-auto p-6 min-h-screen">
      <div className="text-center mb-8">
        <h2 className="text-4xl font-extrabold text-gray-800">
          Leaderboard des meilleurs buveurs
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Voyez où vous vous situez parmi les meilleurs buveurs de bière !
        </p>
      </div>
      {isLoading ? (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            className="text-4xl text-yellow-500"
          />
        </div>
      ) : (
        <ul className="space-y-4">
          {users?.map((user, index) => (
            <li
              key={user._id}
              className={`flex flex-col md:flex-row items-center p-4 bg-white rounded-md shadow-md ${
                index < 3
                  ? "border-l-4 border-yellow-500"
                  : "border-l-4 border-gray-300"
              }`}
            >
              <div className="flex-shrink-0 mr-4 mb-4 md:mb-0">
                <FontAwesomeIcon
                  icon={faMedal}
                  className={`text-3xl ${
                    index === 0
                      ? "text-yellow-500"
                      : index === 1
                      ? "text-gray-500"
                      : index === 2
                      ? "text-brown-500"
                      : "text-gray-300"
                  }`}
                />
              </div>
              <div className="flex-1 text-center md:text-left mb-4 md:mb-0">
                <h3 className="text-xl font-bold text-gray-700">
                  {user.username}
                </h3>
                <p className="text-gray-500">
                  {userBeers[user._id] || 0} bières - {getSchoolName(user._id)}
                </p>
              </div>
              <div className="flex-shrink-0 text-gray-600 flex items-center justify-center mb-4 md:mb-0">
                <FontAwesomeIcon
                  icon={faBeer}
                  className="mr-2 text-yellow-500"
                />
                {userBeers[user._id] || 0}
              </div>
              {user._id === userId && (
                <div className="flex items-center space-x-2 md:ml-5">
                  <Button onClick={handleRemoveBeer}>
                    <FontAwesomeIcon icon={faMinus} className="mr-1" />
                    Retirer
                  </Button>
                  <Button link={"/add-beer"}>
                    <FontAwesomeIcon icon={faPlus} className="mr-1" />
                    Ajouter
                  </Button>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
      {showRemoveModal && (
        <RemoveBeerModal
          beerId={lastBeerId}
          onClose={() => {
            setShowRemoveModal(false);
            fetchData();
          }}
        />
      )}
    </div>
  );
};

export default BeerList;
