import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faGraduationCap,
  faInfoCircle,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-6 mt-10">
      <div className="max-w-4xl mx-auto text-center">
        <div className="mb-4">
          <FontAwesomeIcon
            icon={faBeer}
            className="text-yellow-500 text-3xl mx-2"
          />
          <FontAwesomeIcon
            icon={faGraduationCap}
            className="text-blue-500 text-3xl mx-2"
          />
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-green-500 text-3xl mx-2"
          />
          <FontAwesomeIcon
            icon={faEnvelope}
            className="text-red-500 text-3xl mx-2"
          />
        </div>
        <p className="">
          Suivez votre consommation de bière et rivalisez avec vos amis !
        </p>
        <div className="mt-4">
          <Link to="/cgu" className=" hover:text-white mx-2">
            CGU
          </Link>
          <Link
            to="/privacy-policy"
            className=" hover:text-white mx-2"
          >
            Politique de Confidentialité
          </Link>
          <Link to="/contact" className=" hover:text-white mx-2">
            Contact
          </Link>
        </div>
        <p className=" mt-2">
          &copy; 2024 Beer Counter. Tous droits réservés.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
