import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getSchoolByCode,
  getStudentsBySchool,
} from "../../services/schools/schools.services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBeer, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { getNumberBeerByUserId } from "../../services/beer/beer.services";

const SchoolDetails = () => {
  const { schoolCode } = useParams();
  const [students, setStudents] = useState([]);
  const [numberBeersOfSchool, setNumberBeersOfSchool] = useState(0);
  const [school, setSchool] = useState(null);

  // Fonction pour récupérer les données de l'école
  useEffect(() => {
    const fetchSchool = async () => {
      try {
        const response = await getSchoolByCode(schoolCode);
        setSchool(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSchool();
  }, [schoolCode]);

  // Fonction pour récupérer les étudiants et le nombre total de bières
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await getStudentsBySchool(schoolCode);
        const studentsData = response.data;

        // Utilisation de Promise.all pour récupérer les bières pour chaque étudiant
        const beerCounts = await Promise.all(
          studentsData.map(async (student) => {
            const beerResponse = await getNumberBeerByUserId(student._id);
            const beerCount = beerResponse?.data?.count || 0;
            return { ...student, beers: beerCount }; // On enrichit chaque étudiant avec son nombre de bières
          })
        );

        // Calculer le nombre total de bières pour l'école
        const totalBeers = beerCounts.reduce(
          (acc, student) => acc + student.beers,
          0
        );

        setNumberBeersOfSchool(totalBeers); // Mettre à jour le total de bières
        setStudents(beerCounts); // Mettre à jour les étudiants avec leurs bières
      } catch (error) {
        console.error(error);
      }
    };

    fetchStudents();
  }, [schoolCode]);

  return (
    <div className="max-w-4xl mx-auto p-6 min-h-screen">
      <div className="text-center mb-8">
        <h2 className="text-4xl font-extrabold text-gray-800">
          Informations sur l'école
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Consultez les étudiants et le nombre total de bières consommées dans
          cette école.
        </p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-bold text-gray-700 mb-4">
          Étudiants de {school?.name}
        </h3>
        <p className="text-gray-600 mb-6">
          Nombre total de bières consommées dans l'école :{" "}
          <span className="font-semibold text-4xl text-yellow-500">
            {numberBeersOfSchool}
          </span>
        </p>
        <ul className="space-y-4">
          {students.map((student) => (
            <li
              key={student._id}
              className="flex items-center p-4 bg-gray-50 rounded-md shadow-md border-l-4 border-yellow-500"
            >
              <div className="flex-shrink-0 mr-4">
                <FontAwesomeIcon
                  icon={faUserGraduate}
                  className="text-3xl text-black"
                />
              </div>
              <div className="flex-1">
                <h4 className="text-xl font-bold text-gray-700">
                  {student.username}
                </h4>
                <p className="text-gray-500 ">
                  Nombre de bières :{" "}
                  <FontAwesomeIcon
                    icon={faBeer}
                    className="text-yellow-500 mr-1"
                  />
                  {student.beers}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SchoolDetails;
