// src/routes.js
import { Navigate } from "react-router-dom";
import Home from "./components/Home";
import BeerList from "./components/Beer/BeerList";
import AddBeer from "./components/Beer/AddBeer";
import Register from "./components/Auth/Register";
import Login from "./components/Auth/Login";
import SchoolList from "./components/School/SchoolList";
import SchoolDetails from "./components/School/SchoolDetails";
import SchoolByRegion from "./components/School/SchoolByRegion";
import CreateSchool from "./components/School/CreateSchool";
import AddStudentToSchool from "./components/School/AddStudentToSchool";
import CGU from "./components/CGU";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Contact from "./components/Contact";
import ResetPassword from "./components/Auth/ResetPassword";
import HomeProfil from "./components/profil/HomeProfil";
import Stats from "./components/profil/Info/Stats";
import HomeParty from "./components/Party/HomeParty";
import WhoPays from "./components/WhoPays/WhoPays";

const routes = (token, user, userId, isAdmin, handleLogin) => [
  { path: "/", element: <Home token={token} /> },
  { path: "/beer-list", element: <BeerList userId={userId} user={user} /> },
  { path: "/school-list", element: <SchoolList /> },
  { path: "/school/:schoolCode", element: <SchoolDetails /> },
  { path: "/schools/region/:region", element: <SchoolByRegion /> },
  { path: "/register", element: <Register /> },
  { path: "/login", element: <Login setToken={handleLogin} /> },
  {
    path: "/add-beer",
    element: token ? <AddBeer userId={userId} /> : <Navigate to="/" />,
  },
  { path: "/party", element: <HomeParty user={user} /> },
  {
    path: "/create-school",
    element: isAdmin ? <CreateSchool /> : <Navigate to="/" />,
  },
  {
    path: "/add-student",
    element: isAdmin ? <AddStudentToSchool /> : <Navigate to="/" />,
  },
  { path: "/cgu", element: <CGU /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/contact", element: <Contact /> },
  { path: "/forgot-password", element: <ResetPassword /> },
  { path: "/profil", element: <HomeProfil user={user} /> },
  { path: "/profil/stats/:userId", element: <Stats user={user} /> },
  { path: "/who-pays", element: <WhoPays /> },

  {
    path: "/profil/badges/:userId",
    element: <Stats user={user} type="badges" />,
  },
  { path: "*", element: <Navigate to="/" /> },
];

export default routes;
