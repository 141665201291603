import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getPartyById = async (partyId) => {
  try {
    const response = await axios.get(`${API_URL}/party/${partyId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createParty = async (party, userId) => {
  console.log(party, userId);
  try {
    const response = await axios.post(
      `${API_URL}/party/create/${userId}`,
      party
    );
    return response.data; // Ensure you return the response data
  } catch (error) {
    console.error(error);
  }
};

export const updateParty = async (partyId, party) => {
  try {
    const response = await axios.put(`${API_URL}/party/${partyId}`, party);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteParty = async (partyId) => {
  try {
    const response = await axios.delete(`${API_URL}/party/${partyId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPartyWhereIamGuest = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/party/i-am-guest/${userId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getMyParty = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/party/my-party/${userId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
