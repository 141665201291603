import React, { useState } from "react";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const badgeImages = {
  "First Sip": "/badges/first_sip.webp",
  "Five Pack": "/badges/five_pack.webp",
  "Rookie Drinker": "/badges/rookie_drinker.webp",
  "Ten Cheers": "/badges/ten_cheers.webp",
  "Social Drinker": "/badges/social.webp",
  "Intermediate Drinker": "/badges/intermedier.webp",
  "Quarter Century": "/badges/quarter_century.webp",
  "Pub Hopper": "/badges/pub_hooper.webp",
  "Big Spender": "/badges/big_spender.webp",
  "Advanced Drinker": "/badges/advanced_drinker.webp",
  "Half Century": "/badges/half_century.webp",
  Connoisseur: "/badges/connoisseur.webp",
  Regular: "/badges/regular.webp",
  "Expert Drinker": "/badges/expert.webp",
  "Century Club": "/badges/century_club.webp",
  "Bar Explorer": "/badges/bar_explorer.webp",
  "Master of Taste": "/badges/master_of_taste.webp",
  "Master Drinker": "/badges/master_drinker.webp",
  "Party Animal": "/badges/party_animal.webp",
  "Beer Marathon": "/badges/beer_marathon.webp",
};

const conditionToGetBadge = {
  "First Sip": "Avoir bu une bière",
  "Five Pack": "Avoir bu 5 bières",
  "Rookie Drinker": "Avoir bu 10 bières",
  "Ten Cheers": "Avoir bu 25 bières",
  "Social Drinker": "Avoir bu dans 3 bars différents",
  "Intermediate Drinker": "Avoir bu dans 5 bars différents",
  "Quarter Century": "Avoir bu pour 100€",
  "Pub Hopper": "Avoir bu pour 500€",
  "Big Spender": "Avoir bu pour 1000€",
  "Advanced Drinker": "Avoir bu pour 5000€",
  "Half Century": "Avoir bu 5 litres",
  Connoisseur: "Avoir bu 10 litres",
  Regular: "Avoir bu 20 litres",
  "Expert Drinker": "Avoir bu 50 litres",
  "Century Club": "Avoir bu 100 litres",
  "Bar Explorer": "Avoir bu dans 10 bars différents",
  "Master of Taste": "Avoir bu dans 20 bars différents",
  "Master Drinker": "Avoir bu dans 30 bars différents",
  "Party Animal": "Avoir bu pendant 10 heures",
  "Beer Marathon": "Avoir bu pendant 24 heures",
};

const Badges = ({ badges, allBadges }) => {
  const [showAllBadges, setShowAllBadges] = useState(false);

  const handleBadgeClick = (badge) => {
    MySwal.fire({
      title: badge.label,
      text: `${conditionToGetBadge[badge.label]}`,
      icon: `${badges.includes(badge.label) ? "success" : "error"}`,
      showConfirmButton: false,
    });
  };

  const visibleBadges = showAllBadges ? allBadges : allBadges.slice(0, 6);

  return (
    <div className="w-full max-w-4xl flex-shrink-0">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Vos badges</h1>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 relative">
        {visibleBadges.map((badge, index) => (
          <motion.div
            key={index}
            onClick={() => handleBadgeClick(badge)}
            whileHover={{ scale: 1.1 }}
            className={`flex flex-col items-center justify-center p-4 rounded-lg shadow-md h-40 ${
              badges.includes(badge.label)
                ? ""
                : "opacity-50 filter grayscale cursor-not-allowed"
            }`}
          >
            <img
              src={badgeImages[badge.label]}
              alt={badge.label}
              className="w-20 h-20 mb-2 rounded-full"
            />
            <span>{badge.label}</span>
          </motion.div>
        ))}
        {!showAllBadges && allBadges.length > 6 && (
          <div className="absolute inset-x-0 bottom-0 flex justify-center items-center">
            <div
              className="bg-white h-16 w-full"
              style={{
                background: "linear-gradient(to bottom, transparent, white)",
              }}
            />
          </div>
        )}
      </div>
      {!showAllBadges && allBadges.length > 6 && (
        <div className="flex justify-center mt-4">
          <button
            onClick={() => setShowAllBadges(true)}
            className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition duration-300"
          >
            Voir plus
          </button>
        </div>
      )}
      {showAllBadges && (
        <div className="flex justify-center mt-4">
          <button
            onClick={() => setShowAllBadges(false)}
            className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition duration-300"
          >
            Voir moins
          </button>
        </div>
      )}
    </div>
  );
};

export default Badges;
