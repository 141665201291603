import React, { useState, useEffect } from "react";
import ModalParty from "./Modal/ModalParty";

import Swal from "sweetalert2";
import {
  deleteParty,
  getMyParty,
  getPartyWhereIamGuest,
} from "../../services/party/party.services";

const HomeParty = ({ user }) => {
  const [openModalParty, setOpenModalParty] = useState(false);
  const [myParties, setMyParties] = useState([]);
  const [guestParties, setGuestParties] = useState([]);
  const [selectedParty, setSelectedParty] = useState(null);

  useEffect(() => {
    const fetchParties = async () => {
      try {
        const myPartiesResponse = await getMyParty(user.userId);
        const guestPartiesResponse = await getPartyWhereIamGuest(user.userId);
        setMyParties(myPartiesResponse);
        setGuestParties(guestPartiesResponse);
      } catch (error) {
        console.error("Failed to fetch parties", error);
      }
    };
    fetchParties();
  }, [user.userId]);

  const handleDeleteParty = async (partyId) => {
    try {
      await deleteParty(partyId);
      setMyParties(myParties.filter((party) => party._id !== partyId));
      Swal.fire({
        icon: "success",
        title: "Soirée supprimée avec succès !",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Failed to delete party", error);
      Swal.fire({
        icon: "error",
        title: "Échec de la suppression de la soirée",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleEditParty = (party) => {
    setSelectedParty(party);
    setOpenModalParty(true);
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <button
          onClick={() => setOpenModalParty(true)}
          className="bg-blue-500 text-white p-2 rounded-md shadow hover:bg-blue-600 transition duration-200"
        >
          Créer une partie
        </button>
      </div>

      <div>
        <h1 className="text-2xl font-bold">Vos Soirées</h1>
        <div className="my-4">
          <h2 className="text-xl font-semibold">Parties créées par vous</h2>
          {myParties.length > 0 ? (
            <ul className="list-disc list-inside">
              {myParties.map((party) => (
                <li
                  key={party._id}
                  className="my-2 flex justify-between items-center"
                >
                  <span>
                    {party.name} - {new Date(party.date).toLocaleDateString()} à{" "}
                    {party.location}
                  </span>
                  <div>
                    <button
                      onClick={() => handleEditParty(party)}
                      className="bg-yellow-500 text-white p-1 rounded-md shadow hover:bg-yellow-600 transition duration-200 mx-2"
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => handleDeleteParty(party._id)}
                      className="bg-red-500 text-white p-1 rounded-md shadow hover:bg-red-600 transition duration-200"
                    >
                      Supprimer
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>Vous n'avez pas encore créé de soirées.</p>
          )}
        </div>

        <div className="my-4">
          <h2 className="text-xl font-semibold">Parties où vous êtes invité</h2>
          {guestParties.length > 0 ? (
            <ul className="list-disc list-inside">
              {guestParties.map((party) => (
                <li key={party._id} className="my-2">
                  {party.name} - {new Date(party.date).toLocaleDateString()} à{" "}
                  {party.location}
                </li>
              ))}
            </ul>
          ) : (
            <p>Vous n'êtes invité à aucune soirée pour le moment.</p>
          )}
        </div>
      </div>

      <ModalParty
        user={user} // Pass the user prop correctly
        isOpen={openModalParty}
        onClose={() => {
          setOpenModalParty(false);
          setSelectedParty(null);
        }}
        party={selectedParty}
      />
    </div>
  );
};

export default HomeParty;
