import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Input = ({
  required = false,
  type = "text",
  placeholder = "",
  isShowPassword = false, // Si c'est un champ de mot de passe à visibilité
  label = "", // Ajout de la prop label
  value,
  onChange,
  onBlur,
  name,
  id,
  hasError = false, // Indique si l'input a une erreur
  success = false, // Indique si l'input est en état de succès
  disabled = false, // Indique si l'input est désactivé
}) => {
  const [showPassword, setShowPassword] = useState(false); // État pour gérer la visibilité du mot de passe

  const baseClasses =
    "w-full px-4 py-2 border rounded focus:outline-none text-primary-600";

  const stateClasses = hasError
    ? "border-red-500 text-red-500"
    : success
    ? "border-green-500 text-green-500"
    : "border-gray-300 text-gray-900";

  const disabledClasses = disabled
    ? "bg-gray-200 cursor-not-allowed"
    : "bg-white";

  return (
    <div className="mb-4 flex flex-col items-start ">
      {label && (
        <label htmlFor={name} className="block text-gray-700 mb-2">
          {label}
        </label>
      )}

      {isShowPassword ? (
        <div className="w-full flex items-center border border-gray-300 rounded-md">
          <FontAwesomeIcon icon={faLock} className="ml-3 text-gray-400" />
          <input
            id={id}
            required={required}
            type={showPassword ? "text" : "password"}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            className={`w-full p-2.5 outline-none ${stateClasses} ${disabledClasses}`}
          />
          <button
            type="button"
            className="ml-3 p-2"
            onClick={() => setShowPassword(!showPassword)}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
        </div>
      ) : (
        <input
          id={id}
          required={required}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          className={`${baseClasses} ${stateClasses} ${disabledClasses}`}
        />
      )}
    </div>
  );
};

export default Input;
