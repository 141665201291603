import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./routes";
import Navigation from "./Navigation";
import { setAuthToken } from "./services/auth/auth.services";
import { Analytics } from "@vercel/analytics/react";
import Footer from "./components/Footer";
import { Button } from "./components/elements/Buttons/Button";

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
    }
  }, [token]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Save the event so it can be triggered later
      setDeferredPrompt(e);
    });
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  const decodeToken = (token) => {
    const payload = token.split(".")[1];
    const decoded = atob(payload);
    return JSON.parse(decoded);
  };

  const isAdmin = token ? decodeToken(token).role === "admin" : false;
  const userId = token ? decodeToken(token).userId : null;
  const user = token ? decodeToken(token) : null;

  const handleLogin = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
    setAuthToken(token);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    setAuthToken(null);
    window.location.href = "/";
  };

  return (
    <Router>
      <Navigation
        token={token}
        user={user}
        isAdmin={isAdmin}
        handleLogout={handleLogout}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
      <div className="p-4">
        {deferredPrompt && (
          <Button
            onClick={handleInstallClick}
            className="fixed bottom-4 right-4 bg-green-500 text-white py-2 px-4 rounded"
          >
            Ajouter à l'écran d'accueil
          </Button>
        )}
        <Routes>
          {routes(token, user, userId, isAdmin, handleLogin).map(
            (route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            )
          )}
        </Routes>
      </div>
      <Analytics />
      <Footer />
    </Router>
  );
};

export default App;
